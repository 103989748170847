import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "./scss/main.scss";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Nav";
import Inicio from "./components/Inicio";
import Footer from "./components/Footer";
import AvisoLegal from "./components/AvisoLegal";
import PoliticaCookies from "./components/PoliticaCookies";
import PoliticaPrivacidad from "./components/PoliticaPrivacidad";
import CondicionesGenerales from "./components/CondicionesGenerales";
import Barcelona from "./components/Barcelona";
import Mallorca from "./components/Mallorca";
import Madrid from "./components/Madrid";
import Malaga from "./components/Malaga";
import ScrollToTop from "react-scroll-to-top";
import Alicante from "./components/Alicante";
import Valencia from "./components/Valencia";
import Prensa from "./components/Prensa/Prensa";

const App = () => {
    return (
        <>
            {" "}
            <ScrollToTop
                color="black"
                width="22"
                height="22"
                smooth={true}
                id="scroll"
            />
            <Navbar />
            <Routes>
                <Route index path="" element={<Inicio />} />
                <Route path="avisoLegal" element={<AvisoLegal />} />
                <Route path="politicaCookies" element={<PoliticaCookies />} />
                <Route
                    path="politicaPrivacidad"
                    element={<PoliticaPrivacidad />}
                />
                <Route
                    path="condicionesGenerales"
                    element={<CondicionesGenerales />}
                />
                <Route path="malaga" element={<Malaga />} />
                <Route path="barcelona" element={<Barcelona />} />
                <Route path="madrid" element={<Madrid />} />
                <Route path="mallorca" element={<Mallorca />} />
                <Route path="alicante" element={<Alicante />} />
                <Route path="valencia" element={<Valencia />} />
                <Route path="prensa" element={<Prensa />} />
            </Routes>
            <Footer />
        </>
    );
};

export default App;
