import botonVerde from "../../assets/images/boton-verde.png";
import malaga from '../../assets/images/flyershome/malaga.png'

import guasones from "../../assets/images/artistas/guasones.png";
import ratonesParanoicos from "../../assets/images/artistas/ratonesParanoicos.png";
import InstagramFeed from "../InstagramFeed";

export const Malaga = () => {
    return (
        <section id="inicio">
            <div className="container container-ciudades" >
                <div className="row justify-content-center">
                    <h1 className="px-2 text-danger text-uppercase text-center text-white">
                        Málaga
                    </h1>
                    <div class="d-flex flex-column px-3 align-items-center" id="flyer">
                        <img src={malaga} 
                            alt="malaga" 
                            width={350}
                             
                        />
                        <a href="https://sfx.janto.es/concierto/dale-argentina-fest-juanse-guasones-malaga-2023/DALEMLG2023" target={"_blank"} rel={"noreferrer"}>
                            <img
                                src={botonVerde}
                                width={150}
                                height={30}
                                alt={botonVerde}
                                id="boton"
                            />
                        </a>
                    </div>
                </div>       
            </div> 
            <div id="artistas">
                <div className="container text-center" >
                    <div className="row justify-content-center">
                        <h3 className="text-uppercase text-center">
                            "¡Dale Argentina Fest llega a Málaga con una noche de rock argentino! El 8 de julio, la Sala Trinchera recibirá a dos grandes bandas: Juanse, de los Ratones Paranoicos, y Guasones, reconocida por sus potentes shows en vivo. Los asistentes podrán disfrutar de un espectáculo lleno de energía y pasión por la música, con canciones emblemáticas del rock argentino y temas de sus últimos discos. ¡No te pierdas esta oportunidad de vivir una noche inolvidable con lo mejor del rock argentino en Málaga!"
                        </h3>
                        <div className="text-align-center" id="fotosArtistas">
                            <img src={guasones} 
                                alt="guasones" 
                                width={200}
                                height={200} 
                            />
                            <img src={ratonesParanoicos} 
                                alt="ratonesParanoicos" 
                                width={200}
                                height={200} 
                            />
                        </div>
                    </div>       
                </div> 
            </div>

            <InstagramFeed />
        </section>
    )
}

export default Malaga
