import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Carrousel = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleDownload = (imageUrl, fileName) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = fileName;

        const newWindow = window.open();
        newWindow.location.href = link.href;
    };

    const previewImages = [
        "https://drive.google.com/uc?id=1_LkyjVygNvDaz_unQAkGfNaBNrenLYvQ",
        "https://drive.google.com/uc?id=1NhuXcw5Dk6Qyj98FfS5DLZoogjJ-Dozb",
        "https://drive.google.com/uc?id=1zQ-q5we5-xNdfEI4ulfRF09B5_01kyah",
        "https://drive.google.com/uc?id=10DsbEOHZ9JIfMLazciKKXv5hBnAmWG-d",
        "https://drive.google.com/uc?id=1D59QTwvPHCbywGAHF6qkW4me2_Bhauh9",
        "https://drive.google.com/uc?id=12wkg-0pGBm_VyXy-CGm0WZ9VIvMUvqmf",
        "https://drive.google.com/uc?id=1POCZj9epveUNTBFBC_i-Y-jbGr-3bncb",
        "https://drive.google.com/uc?id=1W_8QDxQtKAYlkrbmvRs368JGm-QFt8Gg",
        "https://drive.google.com/uc?id=1kuJWMc_53HMbAEu8ogexNpeOdDdgtdUa",
        "https://drive.google.com/uc?id=1ThaqAArqZKHQ1LbdAmSB_isjP1XI65xG",
        "https://drive.google.com/uc?id=1D76fKofZXzay3HJjgu3xjIJ3bW9ho2Ys",
        "https://drive.google.com/uc?id=1wQtkiVoXeBNIftMJbvWYgeOJCdmiYWva",

        "https://drive.google.com/uc?id=1nw63WgAAmhckRCEOPm78AhotOurgSEzA",
        "https://drive.google.com/uc?id=1SzcTgLj81HZuaEEbTgNCggdxSJhrsEuI",
        "https://drive.google.com/uc?id=1KSSjdd4vlelbWIyYMK_mz7foFw9PcnGs",
        "https://drive.google.com/uc?id=14jhMlkZ1nEV7aoxtwB5xWzARfQYx2xYT",
        "https://drive.google.com/uc?id=1I_CJckJEDUL9FjeW1s4jvDBN72hUzj2S",
        "https://drive.google.com/uc?id=1LADpwemPyy93pH4oclM9v9OpIAvfi2Lk",
        "https://drive.google.com/uc?id=1GWZpZ7pCESvv0WPcF4hk7VSOkAaxUdsX",

        "https://drive.google.com/uc?id=1CwzTQgI4cMFc1LXrRAfXIoIwLCAtyLpg",
        "https://drive.google.com/uc?id=1B3MFjabJDMid1gLDqDhkche06KNKgqxS",
        "https://drive.google.com/uc?id=1NmbGgKsDuPfbywhV-FOdmIBw8PkgH_cx",
        "https://drive.google.com/uc?id=1Hl-kiM3pjVYLpHhrUKwT2605ppncsm8m",
        "https://drive.google.com/uc?id=1PLEMU5eFWTaRuN3mZO2C0Saq11OzSORn",
        "https://drive.google.com/uc?id=1SYvlKNi6faNX7UA4ChmZg2nnugoy1Lsi",
        "https://drive.google.com/uc?id=1cmmgtwuwLlPRCMSiDatd-L1sYICRjiCN",
        "https://drive.google.com/uc?id=1lfBSK4IxG0Y2VUQYOIPCX5ojxk7MuNde",
        "https://drive.google.com/uc?id=1nVlvwwCadUc7MjzhWKPPcDs1u3w45SVE",

        "https://drive.google.com/uc?id=13o800gDx4bwcjIundiw-Op10mESiyx-g",
        "https://drive.google.com/uc?id=17-4hKZvMSJqDrhCI8qOPpMICIw9ZKsSK",
        "https://drive.google.com/uc?id=1M3vW4o4gm9xuxdc0ECgsbeQY9IGhnsh7",
        "https://drive.google.com/uc?id=1f8kWjRtrylqVA2k3rZmtSTSn2hCY7wgi",
        "https://drive.google.com/uc?id=1gDnQ-82KuGYYz3WmvJSOAusqN5K8m_m9",
        "https://drive.google.com/uc?id=1tu8Uut4wHlNwhgm93rpsB_urLbDeAkUf",
        "https://drive.google.com/uc?id=1vIm8b90eJQzpfSeD9i516Ugml-MqjeYv",
        "https://drive.google.com/uc?id=1zbrfTR1L1yzuu-24SWzN5mq-sof4Q_cg",
        "https://drive.google.com/uc?id=1NB7H-OhdrdVmt9bY_SZvgN-mTOrJlnzt",

        "https://drive.google.com/uc?id=1-CYYcYchBUVHvMtimJ8R5H4ogFISbAfF",
        "https://drive.google.com/uc?id=19Eb0lEwhtPwKCAmf2LtvQvlyrTO514-x",
        "https://drive.google.com/uc?id=1FAw9zTFGV7Crqxamlm-NuGacKNg5LELJ",
        "https://drive.google.com/uc?id=1G77mMLSQgJRew8wMd5u9CV3mAmgTKRoV",
        "https://drive.google.com/uc?id=1M-EIjVJ3OyhKDp4dzl23WjH-nfmA_isT",
        "https://drive.google.com/uc?id=1MLmRyLOJGvksd9TIUrjuwepYlqmtweQ3",
        "https://drive.google.com/uc?id=1N8lcxbhu3ly9XterMXqbYFFZw3h0Zocr",
        "https://drive.google.com/uc?id=1Nhr_6Zeur0wtKn0ZXf8gCp_cUvyA78kt",
        "https://drive.google.com/uc?id=1RVTgkAtPetimG8hzF72t1iVGmwHnQi-w",
        "https://drive.google.com/uc?id=1gtuiqQZxPfD6BSJR14HGoIHTckaG42D7",
        "https://drive.google.com/uc?id=1jFbqKXgZ8uUzMxs9z6mcyVlsrsvBHpxh",
        "https://drive.google.com/uc?id=1tI88Yos664hKY4JXm3lPEhfl4P_U4aF5",
        "https://drive.google.com/uc?id=1voJA2Osjzab3j2QMyzUr-7j8a3K0cPbv",



        "https://drive.google.com/uc?id=1b2Vki-hCRuTLPTYsfSSpTTyy2voX910b",
        "https://drive.google.com/uc?id=19VGAZsqUwKGgpXrzDOwkIhWpzATelPPy",
        "https://drive.google.com/uc?id=1JnUV2TrG27ywVZbk5Fpq51A1b4KcG2ZV",
        "https://drive.google.com/uc?id=1cH_5090Essa8znKLoKx1Sh7QiMjjhKMy",

        "https://drive.google.com/uc?id=1USmWRfq5KBP40AFCfnvc_EMWNSZcYjR3",
        "https://drive.google.com/uc?id=1D-uyoJ84cYp5KpDJmk-EPzoURA-UPRTI",
        "https://drive.google.com/uc?id=1Jt5N4QdrO4JmdUUmz-7Xt27XM0HUFCjG",
        "https://drive.google.com/uc?id=1M1nDROEeSFZWuiK9GtpEjjY8qyBU5HyB",
        "https://drive.google.com/uc?id=1hcV2pSh0bxavoTfkqprbTaKpkg3Ft8Zb",
        "https://drive.google.com/uc?id=1qbIYu06rrv-E4Yq8u3cr4WchM9ZlHZrR",
        "https://drive.google.com/uc?id=1y-tqogxCGfEZVFqMb_UhXNEiM2Cyv8Ye",
        "https://drive.google.com/uc?id=1y4aL7ETkmAHs7G8i7MP94-FSiMhtIH10",

        "https://drive.google.com/uc?id=1FBC6PT2EREYVtcog4KorWu0H-1-9Ww6L",
        "https://drive.google.com/uc?id=11VmHJUIN9wyaldj7sDaJYI5XGxfPNfh4",
        "https://drive.google.com/uc?id=11rmfrp5MhecKavmYVerbsckOMR22nyjp",
        "https://drive.google.com/uc?id=1E2IRZ4i1qMlAVj-OAvpIBA0vxiLSHJsT",
        "https://drive.google.com/uc?id=1FvFIv0EhivqVm4jOSMQ4CmzREXc1FP3Y",
        "https://drive.google.com/uc?id=1PrquUxVZaC0nDLGd0k0pHrJe8M9A1STI",
        "https://drive.google.com/uc?id=1V2gC-zPBZtpZ-2jwYpZKEeLaNL03UPzc",
        "https://drive.google.com/uc?id=1WUaPEfIER3wVQMGnpIbrWxSLz3Ixx5mJ",
        "https://drive.google.com/uc?id=1bzrgOW-ou-CEhgoePRGDqOt2ynzYkhAc",

        "https://drive.google.com/uc?id=10gkj-c5k4XEChjvdBRxFZiGaesrsoFDl",
        "https://drive.google.com/uc?id=17dGz4iG0iXob9H5JjBQAdcUhb3es17Ra",
        "https://drive.google.com/uc?id=19UzbjemMPUPlebyfjdlKwmtatQcJe26R",
        "https://drive.google.com/uc?id=1AzaGxJGuZR5PWml0yfFQO-mv3mpuH9fc",
        "https://drive.google.com/uc?id=1Sd9RvHgnAyC4Lxc9dYfGOLGKaeFC0nJP",
        "https://drive.google.com/uc?id=1Umo0pXDfu67HhFJoBySb_NUxdi8d6Mnm",
        "https://drive.google.com/uc?id=1dnToAqdMRciKvZ-Ewb928XXBG7BVl8HQ",
        "https://drive.google.com/uc?id=1ojyG--nTa36DgsevJtAGhrUd3B-Io3l2",
        "https://drive.google.com/uc?id=1seyWsMpiiFVWYHdUuZOP_lmcDtxj9s4q",
        "https://drive.google.com/uc?id=1uPvhyePSJjYu54mX5kfpcyhLpejm1IaT",

        "https://drive.google.com/uc?id=1PAgoAzJdJVa21im2AJGWcEoOgvd08TRD",
        "https://drive.google.com/uc?id=16jRaM_tyN6HC_p4xMdtEJBA5TmGQCtS0",
        "https://drive.google.com/uc?id=19By7aPW3PmlArEnBaH-0o1853YeWqFhW",
        "https://drive.google.com/uc?id=1AEiVBUTjc5ZLeUsuagkwzrS55wEE8KTx",
        "https://drive.google.com/uc?id=1CrOOLSET6ohNNZ_GtTtoiQub1jms7TKJ",
        "https://drive.google.com/uc?id=1FinHFyKAV74WCj-7s76g9oZkme0EdpUh",
        "https://drive.google.com/uc?id=1HbLmJhImZzjyyoxH4QfbYyae1dGUZ6aw",
        "https://drive.google.com/uc?id=1SzQD3YDJ2n3NdWRnX4cO-Xi45rPHeq-L",

        "https://drive.google.com/uc?id=11J90HI_9WXZPhncgYM6v-7cMyheXof_e",
        "https://drive.google.com/uc?id=120k2UWW_0mBmk1In7o6tq4fVGEcLutjt",
        "https://drive.google.com/uc?id=12zmuF2Vv-TEUBN-4L9p75-L8I4XUXJww",
        "https://drive.google.com/uc?id=18h3S7MXPcN_ESNUOqDpz6XFM1Tm0FSJT",
        "https://drive.google.com/uc?id=1Al7vd4iY4qbeyAMm7MkXVj-DeEGm5Egq",
        "https://drive.google.com/uc?id=1CLcxtl2Ab0JPVOPFIxWDlqE4ABmgS2oh",
        "https://drive.google.com/uc?id=1CuEWbluZ4B4rp3VD69VlWa6W2ic8uzrG",
        "https://drive.google.com/uc?id=1F2WKfxYoR-LHxxWNZwjZRy1tPmTHqi_D",
        "https://drive.google.com/uc?id=1GYMrN5SDBlUCeS9u8ssdE3YrpbpTH3wu",
        "https://drive.google.com/uc?id=1IsvzrBQ1QPsJv_i-2gQE-T809gdNOeMZ",
        "https://drive.google.com/uc?id=1NMN_hi-2of7RNZ0g9Uq2tI0WzdPLzLYZ",
        "https://drive.google.com/uc?id=1RhfMrREz62fVt9TK05fG3RJF6QpZIf-M",
        "https://drive.google.com/uc?id=1SFLAiTdSp2k802AhxTu4TgwZYw7XLW33",
        "https://drive.google.com/uc?id=1W7sOkcJQkFhCw4FrcZRLii1t7SVhp1gH",
        "https://drive.google.com/uc?id=1XyXbA0t7bT0RaQaBMyCWzgJM6ZJW8KT1",
        "https://drive.google.com/uc?id=1_PBZvM_SyZtUM7POz80nydR2tnMmPW9A",
        "https://drive.google.com/uc?id=1aI3aoZJXicQLkgWBaqIbBSexFIfI6emB",
        "https://drive.google.com/uc?id=1lecvMzg00UL9r81cT-onDdukEHHYAHIi"
    ];

    const images = [
        "https://drive.google.com/uc?id=1_LkyjVygNvDaz_unQAkGfNaBNrenLYvQ",
        "https://drive.google.com/uc?id=1NhuXcw5Dk6Qyj98FfS5DLZoogjJ-Dozb",
        "https://drive.google.com/uc?id=1zQ-q5we5-xNdfEI4ulfRF09B5_01kyah",
        "https://drive.google.com/uc?id=10DsbEOHZ9JIfMLazciKKXv5hBnAmWG-d",
        "https://drive.google.com/uc?id=1D59QTwvPHCbywGAHF6qkW4me2_Bhauh9",
        "https://drive.google.com/uc?id=12wkg-0pGBm_VyXy-CGm0WZ9VIvMUvqmf",
        "https://drive.google.com/uc?id=1POCZj9epveUNTBFBC_i-Y-jbGr-3bncb",
        "https://drive.google.com/uc?id=1W_8QDxQtKAYlkrbmvRs368JGm-QFt8Gg",
        "https://drive.google.com/uc?id=1kuJWMc_53HMbAEu8ogexNpeOdDdgtdUa",
        "https://drive.google.com/uc?id=1ThaqAArqZKHQ1LbdAmSB_isjP1XI65xG",
        "https://drive.google.com/uc?id=1D76fKofZXzay3HJjgu3xjIJ3bW9ho2Ys",
        "https://drive.google.com/uc?id=1wQtkiVoXeBNIftMJbvWYgeOJCdmiYWva",

        "https://drive.google.com/uc?id=1nw63WgAAmhckRCEOPm78AhotOurgSEzA",
        "https://drive.google.com/uc?id=1SzcTgLj81HZuaEEbTgNCggdxSJhrsEuI",
        "https://drive.google.com/uc?id=1KSSjdd4vlelbWIyYMK_mz7foFw9PcnGs",
        "https://drive.google.com/uc?id=14jhMlkZ1nEV7aoxtwB5xWzARfQYx2xYT",
        "https://drive.google.com/uc?id=1I_CJckJEDUL9FjeW1s4jvDBN72hUzj2S",
        "https://drive.google.com/uc?id=1LADpwemPyy93pH4oclM9v9OpIAvfi2Lk",
        "https://drive.google.com/uc?id=1GWZpZ7pCESvv0WPcF4hk7VSOkAaxUdsX",

        "https://drive.google.com/uc?id=1CwzTQgI4cMFc1LXrRAfXIoIwLCAtyLpg",
        "https://drive.google.com/uc?id=1B3MFjabJDMid1gLDqDhkche06KNKgqxS",
        "https://drive.google.com/uc?id=1NmbGgKsDuPfbywhV-FOdmIBw8PkgH_cx",
        "https://drive.google.com/uc?id=1Hl-kiM3pjVYLpHhrUKwT2605ppncsm8m",
        "https://drive.google.com/uc?id=1PLEMU5eFWTaRuN3mZO2C0Saq11OzSORn",
        "https://drive.google.com/uc?id=1SYvlKNi6faNX7UA4ChmZg2nnugoy1Lsi",
        "https://drive.google.com/uc?id=1cmmgtwuwLlPRCMSiDatd-L1sYICRjiCN",
        "https://drive.google.com/uc?id=1lfBSK4IxG0Y2VUQYOIPCX5ojxk7MuNde",
        "https://drive.google.com/uc?id=1nVlvwwCadUc7MjzhWKPPcDs1u3w45SVE",

        "https://drive.google.com/uc?id=13o800gDx4bwcjIundiw-Op10mESiyx-g",
        "https://drive.google.com/uc?id=17-4hKZvMSJqDrhCI8qOPpMICIw9ZKsSK",
        "https://drive.google.com/uc?id=1M3vW4o4gm9xuxdc0ECgsbeQY9IGhnsh7",
        "https://drive.google.com/uc?id=1f8kWjRtrylqVA2k3rZmtSTSn2hCY7wgi",
        "https://drive.google.com/uc?id=1gDnQ-82KuGYYz3WmvJSOAusqN5K8m_m9",
        "https://drive.google.com/uc?id=1tu8Uut4wHlNwhgm93rpsB_urLbDeAkUf",
        "https://drive.google.com/uc?id=1vIm8b90eJQzpfSeD9i516Ugml-MqjeYv",
        "https://drive.google.com/uc?id=1zbrfTR1L1yzuu-24SWzN5mq-sof4Q_cg",
        "https://drive.google.com/uc?id=1NB7H-OhdrdVmt9bY_SZvgN-mTOrJlnzt",

        "https://drive.google.com/uc?id=1-CYYcYchBUVHvMtimJ8R5H4ogFISbAfF",
        "https://drive.google.com/uc?id=19Eb0lEwhtPwKCAmf2LtvQvlyrTO514-x",
        "https://drive.google.com/uc?id=1FAw9zTFGV7Crqxamlm-NuGacKNg5LELJ",
        "https://drive.google.com/uc?id=1G77mMLSQgJRew8wMd5u9CV3mAmgTKRoV",
        "https://drive.google.com/uc?id=1M-EIjVJ3OyhKDp4dzl23WjH-nfmA_isT",
        "https://drive.google.com/uc?id=1MLmRyLOJGvksd9TIUrjuwepYlqmtweQ3",
        "https://drive.google.com/uc?id=1N8lcxbhu3ly9XterMXqbYFFZw3h0Zocr",
        "https://drive.google.com/uc?id=1Nhr_6Zeur0wtKn0ZXf8gCp_cUvyA78kt",
        "https://drive.google.com/uc?id=1RVTgkAtPetimG8hzF72t1iVGmwHnQi-w",
        "https://drive.google.com/uc?id=1gtuiqQZxPfD6BSJR14HGoIHTckaG42D7",
        "https://drive.google.com/uc?id=1jFbqKXgZ8uUzMxs9z6mcyVlsrsvBHpxh",
        "https://drive.google.com/uc?id=1tI88Yos664hKY4JXm3lPEhfl4P_U4aF5",
        "https://drive.google.com/uc?id=1voJA2Osjzab3j2QMyzUr-7j8a3K0cPbv",



        "https://drive.google.com/uc?id=1b2Vki-hCRuTLPTYsfSSpTTyy2voX910b",
        "https://drive.google.com/uc?id=19VGAZsqUwKGgpXrzDOwkIhWpzATelPPy",
        "https://drive.google.com/uc?id=1JnUV2TrG27ywVZbk5Fpq51A1b4KcG2ZV",
        "https://drive.google.com/uc?id=1cH_5090Essa8znKLoKx1Sh7QiMjjhKMy",

        "https://drive.google.com/uc?id=1USmWRfq5KBP40AFCfnvc_EMWNSZcYjR3",
        "https://drive.google.com/uc?id=1D-uyoJ84cYp5KpDJmk-EPzoURA-UPRTI",
        "https://drive.google.com/uc?id=1Jt5N4QdrO4JmdUUmz-7Xt27XM0HUFCjG",
        "https://drive.google.com/uc?id=1M1nDROEeSFZWuiK9GtpEjjY8qyBU5HyB",
        "https://drive.google.com/uc?id=1hcV2pSh0bxavoTfkqprbTaKpkg3Ft8Zb",
        "https://drive.google.com/uc?id=1qbIYu06rrv-E4Yq8u3cr4WchM9ZlHZrR",
        "https://drive.google.com/uc?id=1y-tqogxCGfEZVFqMb_UhXNEiM2Cyv8Ye",
        "https://drive.google.com/uc?id=1y4aL7ETkmAHs7G8i7MP94-FSiMhtIH10",

        "https://drive.google.com/uc?id=1FBC6PT2EREYVtcog4KorWu0H-1-9Ww6L",
        "https://drive.google.com/uc?id=11VmHJUIN9wyaldj7sDaJYI5XGxfPNfh4",
        "https://drive.google.com/uc?id=11rmfrp5MhecKavmYVerbsckOMR22nyjp",
        "https://drive.google.com/uc?id=1E2IRZ4i1qMlAVj-OAvpIBA0vxiLSHJsT",
        "https://drive.google.com/uc?id=1FvFIv0EhivqVm4jOSMQ4CmzREXc1FP3Y",
        "https://drive.google.com/uc?id=1PrquUxVZaC0nDLGd0k0pHrJe8M9A1STI",
        "https://drive.google.com/uc?id=1V2gC-zPBZtpZ-2jwYpZKEeLaNL03UPzc",
        "https://drive.google.com/uc?id=1WUaPEfIER3wVQMGnpIbrWxSLz3Ixx5mJ",
        "https://drive.google.com/uc?id=1bzrgOW-ou-CEhgoePRGDqOt2ynzYkhAc",

        "https://drive.google.com/uc?id=10gkj-c5k4XEChjvdBRxFZiGaesrsoFDl",
        "https://drive.google.com/uc?id=17dGz4iG0iXob9H5JjBQAdcUhb3es17Ra",
        "https://drive.google.com/uc?id=19UzbjemMPUPlebyfjdlKwmtatQcJe26R",
        "https://drive.google.com/uc?id=1AzaGxJGuZR5PWml0yfFQO-mv3mpuH9fc",
        "https://drive.google.com/uc?id=1Sd9RvHgnAyC4Lxc9dYfGOLGKaeFC0nJP",
        "https://drive.google.com/uc?id=1Umo0pXDfu67HhFJoBySb_NUxdi8d6Mnm",
        "https://drive.google.com/uc?id=1dnToAqdMRciKvZ-Ewb928XXBG7BVl8HQ",
        "https://drive.google.com/uc?id=1ojyG--nTa36DgsevJtAGhrUd3B-Io3l2",
        "https://drive.google.com/uc?id=1seyWsMpiiFVWYHdUuZOP_lmcDtxj9s4q",
        "https://drive.google.com/uc?id=1uPvhyePSJjYu54mX5kfpcyhLpejm1IaT",

        "https://drive.google.com/uc?id=1PAgoAzJdJVa21im2AJGWcEoOgvd08TRD",
        "https://drive.google.com/uc?id=16jRaM_tyN6HC_p4xMdtEJBA5TmGQCtS0",
        "https://drive.google.com/uc?id=19By7aPW3PmlArEnBaH-0o1853YeWqFhW",
        "https://drive.google.com/uc?id=1AEiVBUTjc5ZLeUsuagkwzrS55wEE8KTx",
        "https://drive.google.com/uc?id=1CrOOLSET6ohNNZ_GtTtoiQub1jms7TKJ",
        "https://drive.google.com/uc?id=1FinHFyKAV74WCj-7s76g9oZkme0EdpUh",
        "https://drive.google.com/uc?id=1HbLmJhImZzjyyoxH4QfbYyae1dGUZ6aw",
        "https://drive.google.com/uc?id=1SzQD3YDJ2n3NdWRnX4cO-Xi45rPHeq-L",

        "https://drive.google.com/uc?id=11J90HI_9WXZPhncgYM6v-7cMyheXof_e",
        "https://drive.google.com/uc?id=120k2UWW_0mBmk1In7o6tq4fVGEcLutjt",
        "https://drive.google.com/uc?id=12zmuF2Vv-TEUBN-4L9p75-L8I4XUXJww",
        "https://drive.google.com/uc?id=18h3S7MXPcN_ESNUOqDpz6XFM1Tm0FSJT",
        "https://drive.google.com/uc?id=1Al7vd4iY4qbeyAMm7MkXVj-DeEGm5Egq",
        "https://drive.google.com/uc?id=1CLcxtl2Ab0JPVOPFIxWDlqE4ABmgS2oh",
        "https://drive.google.com/uc?id=1CuEWbluZ4B4rp3VD69VlWa6W2ic8uzrG",
        "https://drive.google.com/uc?id=1F2WKfxYoR-LHxxWNZwjZRy1tPmTHqi_D",
        "https://drive.google.com/uc?id=1GYMrN5SDBlUCeS9u8ssdE3YrpbpTH3wu",
        "https://drive.google.com/uc?id=1IsvzrBQ1QPsJv_i-2gQE-T809gdNOeMZ",
        "https://drive.google.com/uc?id=1NMN_hi-2of7RNZ0g9Uq2tI0WzdPLzLYZ",
        "https://drive.google.com/uc?id=1RhfMrREz62fVt9TK05fG3RJF6QpZIf-M",
        "https://drive.google.com/uc?id=1SFLAiTdSp2k802AhxTu4TgwZYw7XLW33",
        "https://drive.google.com/uc?id=1W7sOkcJQkFhCw4FrcZRLii1t7SVhp1gH",
        "https://drive.google.com/uc?id=1XyXbA0t7bT0RaQaBMyCWzgJM6ZJW8KT1",
        "https://drive.google.com/uc?id=1_PBZvM_SyZtUM7POz80nydR2tnMmPW9A",
        "https://drive.google.com/uc?id=1aI3aoZJXicQLkgWBaqIbBSexFIfI6emB",
        "https://drive.google.com/uc?id=1lecvMzg00UL9r81cT-onDdukEHHYAHIi"
    ];

    const handleImageClick = (index) => {
        const imageUrl = images[index];
        const fileName = `imagen${index + 1}.jpg`;
        handleDownload(imageUrl, fileName);
    };

    return (
        <div className="carousel-wrapper">
            <Carousel
                autoPlay
                dynamicHeight={true}
                interval={1500}
                infiniteLoop
                thumbWidth={isMobile ? 80 : 120}
                showIndicators={false}
                id="carrousel-img"
                selectedItem={currentIndex}
                onChange={(index) => setCurrentIndex(index)}
            >
                {previewImages.map((previewImageUrl, index) => (
                    <div key={index} onClick={() => handleImageClick(index)}>
                        <img alt="" src={previewImageUrl} />
                        <div className="download-overlay">Descargar</div>
                    </div>
                ))}
            </Carousel>
            <button
                className="download-button"
                onClick={() => handleImageClick(currentIndex)}
            >
                Descargar Imagen
            </button>
        </div>
    );
};
