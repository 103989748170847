import botonVerde from "../../assets/images/boton-verde.png";
import madridOk from "../../assets/images/flyershome/madrid.jpeg";

import airbag from "../../assets/images/artistas/airbag.png";
import coti from "../../assets/images/artistas/coti.jpeg";
import damasgratis from "../../assets/images/artistas/damasgratis.jpg";
import divididos from "../../assets/images/artistas/divididos.jpeg";
import guasones from "../../assets/images/artistas/guasones.png";
import ratonesParanoicos from "../../assets/images/artistas/ratonesParanoicos.png";
import flyerValencia from "../../assets/images/flyershome/Flyer-Valencia.jpg";
import InstagramFeed from "../InstagramFeed";

export const Valencia = () => {
    return (
        <section id="inicio">
            <div className="container container-ciudades">
                <div className="row justify-content-center">
                    <h1 className="px-2 text-danger text-uppercase text-center text-white">
                        Valencia
                    </h1>
                    <div
                        class="d-flex flex-column px-3 align-items-center"
                        id="flyer"
                    >
                        <img
                            src={flyerValencia}
                            alt="valencia"
                            width={350}
                            height={500}
                        />
                        <a
                            href="https://sfx.janto.es/concierto/dale-argentina-fest-airbag-juanse-guasones-valencia-2023/DALEVAL2023"
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <img
                                src={botonVerde}
                                width={150}
                                height={30}
                                alt={botonVerde}
                                id="boton"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div id="artistas">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <h3 className="text-uppercase text-center">
                            "¡Prepárense para una experiencia musical sin igual!
                            Este Julio, la ciudad de Valencia se transformará en
                            el escenario de uno de los festivales de música más
                            esperados del año. Por primera vez, la capital
                            europea tendrá el honor de acoger el primer festival
                            de música argentina, un evento que tendrán la
                            oportunidad de disfrutar de las actuaciones de
                            algunos de los mejores artistas argentinos."
                        </h3>
                        <div className="text-align-center" id="fotosArtistas">
                            <img
                                src={airbag}
                                alt="airbag"
                                width={200}
                                height={200}
                            />
                            <img
                                src={guasones}
                                alt="guasones"
                                width={200}
                                height={200}
                            />
                            <img
                                src={ratonesParanoicos}
                                alt="ratonesParanoicos"
                                width={200}
                                height={200}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <InstagramFeed />
        </section>
    );
};

export default Valencia;
