import { useState, useEffect } from "react";
import axios from "axios";

const InstagramFeed = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios
            .get("https://feeds.behold.so/ulYIvwDQ59S6YAV3pUjB")
            .then(({ data }) => setPosts(data.slice(0, 6)))
            .catch((error) => console.error(error));
    }, []);

    const handleClick = (url) => {
        window.open(url, "_blank");
    };

    return (
        <>
            <section className="page-section" id='ig-section'>
                <div id="instagram-container">
                    <h1 className="text-center">
                        {" "}
                        <a
                            className="btn-social"
                            href="https://www.instagram.com/daleargentinafest/"
                            target={"_blank"}
                            rel="noreferrer"
                            aria-labelledby="Instagram DALE ARGENTINA FEST"
                        >
                            <i className="fab fa-fw fa-instagram fa-lg"></i>
                        </a>
                    </h1>
                    <h2 className="text-center text-uppercase mb-4">
                        instagram feed
                    </h2>
                    <h3 className="text-center text-uppercase">
                        Dale Argentina Fest
                    </h3>
                    <div className="container">
                        <div className="row">
                            {posts.map((post) => (
                                <div
                                    className="col-md-4 col-12 instagram-post"
                                    key={post.id}
                                >
                                    <img
                                        id='ig-img'
                                        src={post.mediaUrl}
                                        alt={post.caption}
                                        className="img-fluid"
                                        onClick={() =>
                                            handleClick(post.permalink)
                                        }
                                    />
{/*                                     <p className="caption">{post.caption}</p> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default InstagramFeed;
