import React from "react";
import ContactoForm from "./ContactoForm";

const Contacto = () => {
    return (
        <>
            <section id="contacto" className="my-0">
                <ContactoForm />
            </section>
        </>
    );
};

export default Contacto;
