import AcreditacionesForm from "./AcreditacionesForm";

const Acreditaciones = () => {
    return (
        <>
            <section id="acreditaciones" className="my-0">
                <AcreditacionesForm />
            </section>
        </>
    );
};

export default Acreditaciones;
