import mallorcaOk from "../../assets/images/flyershome/Mallorca16Julio.jpeg";
import botonAmarillo from "../../assets/images/boton-amarillo.png";

import airbag from "../../assets/images/artistas/airbag.png";
import coti from "../../assets/images/artistas/coti.jpeg";
import damasgratis from "../../assets/images/artistas/damasgratis.jpg";
import divididos from "../../assets/images/artistas/divididos.jpeg";
import guasones from "../../assets/images/artistas/guasones.png";
import ratonesParanoicos from "../../assets/images/artistas/ratonesParanoicos.png";
import InstagramFeed from "../InstagramFeed";

export const Mallorca = () => {
    return (
        <section id="inicio">
            <div className="container container-ciudades" >
                <div className="row justify-content-center">
                    <h1 className="px-2 text-danger text-uppercase text-center text-white">
                        Mallorca
                    </h1>
                    <div class="d-flex flex-column px-3 align-items-center" id="flyer">
                        <img src={mallorcaOk} 
                            alt="mallorca" 
                            width={350}
                            
                        />
                        <a href="https://bit.ly/dalemallorca" target={"_blank"} rel={"noreferrer"}>
                            <img
                                src={botonAmarillo}
                                width={150}
                                height={30}
                                alt={botonAmarillo}
                                id="boton"
                            />
                        </a>
                    </div>
                </div>       
            </div> 
            <div id="artistas">
                <div className="container text-center" >
                    <div className="row justify-content-center">
                        <h3 className="text-uppercase text-center">
                        "¡Prepárense para una experiencia musical sin igual! Este Julio, la ciudad de Mallorca se transformará en el escenario de uno de los festivales de música más esperados del año. Por primera vez, la capital europea tendrá el honor de acoger el primer festival de música argentina, un evento que tendrán la oportunidad de disfrutar de las actuaciones de algunos de los mejores artistas argentinos."
                        </h3>
                        <div className="text-align-center" id="fotosArtistas">
                            <img src={airbag} 
                                alt="airbag" 
                                width={200}
                                height={200}
                            />
                            <img src={coti} 
                                alt="coti" 
                                width={200}
                                height={200} 
                            />
                            <img src={damasgratis} 
                                alt="damasgratis" 
                                width={200}
                                height={200} 
                            />
                            <img src={divididos} 
                                alt="divididos" 
                                width={200}
                                height={200} 
                            />
                            <img src={guasones} 
                                alt="guasones" 
                                width={200}
                                height={200} 
                            />
                            <img src={ratonesParanoicos} 
                                alt="ratonesParanoicos" 
                                width={200}
                                height={200} 
                            />
                        </div>
                    </div>       
                </div> 
            </div>

            <InstagramFeed />
        </section>
    )
}

export default Mallorca
