import { useState } from "react";
import { Container } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carrousel } from "./Carrousel/Carrousel";
import { CarrouselDos } from "./CarrouselDos/CarrouselDos";
import ShowMoreText from "react-show-more-text";

export const Prensa = () => {
    const [isTextExpanded, setIsTextExpanded] = useState(false);

    const handleToggleText = () => {
        setIsTextExpanded(!isTextExpanded);
    };

    return (
        <section className="page-section" id="prensa">
            <Container>
                <h1 className="text-center mb-4">PRENSA</h1>

                <h3 className="text-center subtitle">Barcelona</h3>
                <h2 className="text-center mb-2">
                    EL DALE ARGENTINA FEST SACUDE BARCELONA CON SU PRIMERA EDICIÓN 
                </h2>
                
                <ShowMoreText
                    lines={3}
                    more={
                        <button className="read-more-button">Leer más</button>
                    }
                    less={
                        <button className="read-more-button">Leer menos</button>
                    }
                    anchorClass="read-more-link"
                    onClick={handleToggleText}
                    expanded={isTextExpanded}
                    className="mb-4 all"
                >
                    <div className="text-container">
                        <p>
                            Barcelona se estremeció al ritmo de la música argentina en la primera edición del Dale Argentina Fest. Durante dos días repletos de emoción y energía, el festival logró cautivar a más de 9 mil personas, consolidándose como un evento imprescindible en la escena musical de la ciudad.
                            <br />
                            <br />
                            El talento argentino hizo vibrar al escenario de Barcelona
                            <br />
                            <br />
                            El Dale Argentina Fest deslumbró a los fanáticos con una selección estelar de artistas en el Pueblo Español. Stereotipos abrió el festival llevando a delante su tributo a Soda Stereo que comenzó a levantar la temperatura para Juanse y las históricas canciones de Ratones Paranoicos. 
                            <br />
                            <br />
                            El ritmo de Guasones siguió en el escenario, para hacer saltar al público y dejar el pogo listo para la aplanadora del rock and roll.  El cierre fue inolvidable de la mano de Divididos con casi 5 mil personas cantando y saltando de la mano de esta histórica banda, que inició su presentación con un video del himno nacional argentino que hizo explotar al público presente.
                            <br />
                            <br />
                            El segundo día no fue menos emocionante. Con el sol inundando el Pueblo Español  Kami y Conti Reynoso llegaron como invitadas sorpresas y dieron inicio a la jornada. Al finalizar, ingresó uno de los mayores representantes argentinos en España, Coti Sorokin que subió al escenario para compartir sus éxitos transatlánticos y recibir al indie-rock de Conociendo Rusia. Ya entrada la noche, Airbag hizo bailar a más de 4 mil personas y dejó el clima perfecto para el cierre estelar de la cumbia de Damas Gratis. 
                            <br />
                            <br />
                            La banda liderada por Pablo Lescano ingresó a las 23 horas con una audiencia repleta que bailó y vibró al ritmo de la cumbia durante una hora y media.  El público no paró de cantar todo el repertorio de la banda cerrando la primera edición de este festival que llegó para quedarse en España.
                            <br />
                            <br />
                            Además de la música vibrante, el Dale Argentina Fest ofreció una experiencia culinaria inigualable y actividades familiares. Los asistentes pudieron disfrutar de una amplia variedad de opciones gastronómicas en los food trucks presentes, como Cerveza Mohou San Miguel, Bebidas Organics Red Bull, Che Chipa, La Frontera con sus mejores pizzas, Brasa y Fuego con sus exquisitas carnes al asador, los deliciosos helados de Guapaletas, la Fábrica de Empanadas, Buenos Aires Gin Tonic y Mardel con su dulce de leche y alfajores.
                            <br />
                            <br />
                            El evento también contó con la participación de destacados stands, como la Asociación de Folklore Argentino en Europa, el arte de Micka Rozzio, la actividad solidaria de No Más Invisibles, las creaciones de la pastelería artesanal Bucky Cake Shop, las últimas tendencias en moda de Camisetazas Store, la pasión creativa de Pamela Lozano Arte, terapias de sanación con Elizabeth Mehr, bombonería, bijou y moda de Yokopalma y Tibeka, las mezclas herbales de Gaia M. Tierra, y servicios de fisioterapia de Daniela Angulo.
                            <br />
                            <br />
                            El Dale Argentina Fest contó con el auspicio institucional del Consulado Argentino en Barcelona, reafirmando su importancia como un evento cultural destacado en la ciudad.
                        </p>
                    </div>
                </ShowMoreText>
            </Container>
            <Container>
                <Carrousel />
            </Container>

            <Container>
                <br />
                <br />
                <br />
                <br />
                <h3 className="text-center subtitle">Madrid</h3>

                <h2 className="text-center mb-2">
                    MADRID VIBRÓ AL RITMO DE LA HISTÓRICA PRIMERA EDICIÓN DEL DALE ARGENTINA FEST 
                </h2>
                
                <ShowMoreText
                    lines={3}
                    more={
                        <button className="read-more-button">Leer más</button>
                    }
                    less={
                        <button className="read-more-button">Leer menos</button>
                    }
                    anchorClass="read-more-link"
                    onClick={handleToggleText}
                    expanded={isTextExpanded}
                    className="mb-4 all"
                >
                    <div className="text-container">
                        <p>
                            Madrid se llenó de energía y pasión con la realización de la histórica primera edición del Dale Argentina Fest en el Espacio Ibercaja Delicias. Con la asistencia de más de 2000 apasionados de la música argentina, el evento único reunió lo mejor de la cultura argentina, brindando una experiencia inolvidable de conexión y descubrimiento.
                            <br />
                            <br />
                            Desde el comienzo de la jornada, los asistentes disfrutaron de una variedad de shows en vivo de folklore, creando un ambiente festivo que envolvió a todas las personas. Además, tuvieron la oportunidad de recorrer los food trucks dispuestos en el lugar, donde pudieron deleitarse con la exquisita gastronomía argentina y descubrir productos auténticos relacionados con la cultura del país.                            
                            <br />
                            <br />
                            El Dale Argentina Fest contó con el auspicio institucional del Consulado Argentino, reforzando la relevancia y el apoyo a este evento de gran magnitud. 
                            <br />
                            <br />
                        </p>
                        <p className="underline-text">
                            La capital española cantó al ritmo de la música argentina:
                        </p>  
                        <p>
                            El escenario del Dale Argentina Fest se iluminó con la presencia de reconocidos artistas que dejaron su huella en Madrid. Guasones, con su potente propuesta musical, cautivó al público con su presentación. El aclamado Coti hizo vibrar a todos con sus melodías inconfundibles, mientras que el histórico líder de Ratones Paranoicos, Juanse, ofreció una actuación llena de energía que culminó con la sorpresa de la noche: la aparición estelar de Fito Paez. Airbag deslumbró con un show a puro rock and roll, seguido de Divididos, que le dio a sus fans un show inolvidable. El cierre ATR estuvo a cargo de Damas Gratis, que hizo que la multitud se entregara a la fiesta.
                            <br />
                            <br />
                        </p>
                        <p className="underline-text">
                            La gastronomía argentina, una experiencia única:
                        </p>
                        <p>
                            Los food trucks presentes en el Dale Argentina Fest deleitaron a los asistentes con una selección de sabores auténticos de Argentina. Tita ofreció sus famosas empanadas y sanguches de miga, mientras que Vaquero ofreció sus carnes a la parrilla. Los helados de Guapaletas refrescaron los paladares, y Mardel endulzó la experiencia con su dulce de leche y alfajores. Y, la bebida oficial del evento, estuvo de la mano de Buenos Aires Gin Tonic.
                            <br />
                            <br />
                            <br />
                            El Dale Argentina Fest en Madrid dejó una huella imborrable en la ciudad, reuniendo música, cultura, gastronomía y pasión en un solo lugar. Este evento histórico marcó el comienzo de una tradición que promete seguir sorprendiendo y conectando a los amantes de la cultura argentina.
                            <br />
                            <br />
                            Hashtag: #daleargentinafest 
                            <br />
                            Instagram: @DaleArgentinaFes
                            <br />
                            Sitio web: https://daleargentinafest.com/
                            </p>
                    </div>
                </ShowMoreText>
            </Container>
            <Container>
                <CarrouselDos />
            </Container>
        </section>
    );
};

export default Prensa;
