import { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const CarrouselDos = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleDownload = (imageUrl, fileName) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = fileName;

        const newWindow = window.open();
        newWindow.location.href = link.href;
    };

    const previewImages = [
		"https://drive.google.com/uc?id=16_BS-gRzotAD7yOSxByhdU1CEqHO0e_U",
		"https://drive.google.com/uc?id=1HOk3SyelJkCqmm0TDvWLLEfEyHWFYYdx",
		"https://drive.google.com/uc?id=1J46j0X7GFW0bW_s6nrpGfhGc0Apy2kxX",
		"https://drive.google.com/uc?id=1O--Dh5U0wC-fv_-IZHWWkBxWpYfB8nbE",
		"https://drive.google.com/uc?id=1SGtznySLWsbzn9-NPtV5X9ECWhh7W2qD",
		"https://drive.google.com/uc?id=1Sr4gZld2xSPXIDJ1eA_zSuBoQ6ufDVpo",
		"https://drive.google.com/uc?id=1WX-WaNNFq23ZG0NKIzYjDhCVWwP4PN7i",
		"https://drive.google.com/uc?id=1Zh6041kRDRM8OOSPuxAkl638rVnAON7x",
		"https://drive.google.com/uc?id=1a-0HPKO8eLFtCh62CfVI4dW0riwSbmB3",
		"https://drive.google.com/uc?id=1eqikZ7HAvSgPNI6SJAL1QjkESGPbgMGC",
		"https://drive.google.com/uc?id=1i4kheSNblrXtNsUgL-j5m4SNBnEG1o7I",
		"https://drive.google.com/uc?id=1mxQjEsr0n8-2N1BHfBEBw3eder1lTKIp",
		"https://drive.google.com/uc?id=1oajVFSu4B2WRoYCbFeXtX1npgt7MdXqY",
		"https://drive.google.com/uc?id=1oymb5dtlNnA2O4J2QYQ4e6-Zs2mVELQJ",
		"https://drive.google.com/uc?id=1p17N3kED2kXRX-HREqo7Pjd3FYhSkhTG",
		"https://drive.google.com/uc?id=1u5nD0okAJaHaX5al0ZrIq130wkH61KEo",

        "https://drive.google.com/uc?id=1D_iQ7SYanq7a-7z-AyQTl3CI90UESb02", 
		"https://drive.google.com/uc?id=17aKrBMqUCeMl1r23c1-wBIlHddPFYP12", 
		"https://drive.google.com/uc?id=1kKrWsDG1zJgJ5Biwmpf971mfc8Wpi9Wt", 
		"https://drive.google.com/uc?id=1uD-kYg9oTyVMoSNwlauiFXcSbzURD7sj", 
		"https://drive.google.com/uc?id=193SszSiKMka5lPH2SlYSA5hKCOUKq-J7",
		"https://drive.google.com/uc?id=16I0K9s4jWUjH5lIxqNxhA1NEoTzkFPkq",

		"https://drive.google.com/uc?id=12QDDVFKAncbCL5ozDSQG5i74jRADM4q5",
        "https://drive.google.com/uc?id=10GPhw9ty7EqOPohlkYsW1N-ZVvxbOczn",
        "https://drive.google.com/uc?id=1zAcjOH_DjJD7bUzOF_R58X4bm2e9FJKo",
        "https://drive.google.com/uc?id=1Q26Vz0AAgayk4fxt8ftOPhsxdPNi2ZMa",
        "https://drive.google.com/uc?id=1aw_m9E62qJtho5c9FUyF09eJVg4VpyF4",

        "https://drive.google.com/uc?id=1F3ZJ9iiLkycUq7vRUs__CA9_Ekfl-ItI",
        "https://drive.google.com/uc?id=1z6oRYngMo2xfmaFcvFvicFomfSx804hm",
        "https://drive.google.com/uc?id=17PChqKOTmdPJai9rHL2cDYk7GGSAVK2z",
        "https://drive.google.com/uc?id=16PdeQwCzkofWykcWKR6rWaGgUUo5Ew4R",

        "https://drive.google.com/uc?id=1IPctNP-kgasUrPRjFGuk4q9OlvQ6rBPK",
        "https://drive.google.com/uc?id=1IX90Adu1BupYQWm5xkeeoMfcNu-l4DrT",
        "https://drive.google.com/uc?id=1fwf8cNPupxJi7Hig7gKSxEdk6aeVtQLq",
		
        "https://drive.google.com/uc?id=1gFAIOXLoIYyrNFoqkun-TpssXE2duLhK",
        "https://drive.google.com/uc?id=1cNyOdzKF55xdSJTp8u6re6a9zzJ_tB9c",
        "https://drive.google.com/uc?id=1PaWUvYNMSsuRrj2PgcvwOmCyzhGl1Kb0"
    ];

    const images = [
        "https://drive.google.com/uc?id=16_BS-gRzotAD7yOSxByhdU1CEqHO0e_U",
		"https://drive.google.com/uc?id=1HOk3SyelJkCqmm0TDvWLLEfEyHWFYYdx",
		"https://drive.google.com/uc?id=1J46j0X7GFW0bW_s6nrpGfhGc0Apy2kxX",
		"https://drive.google.com/uc?id=1O--Dh5U0wC-fv_-IZHWWkBxWpYfB8nbE",
		"https://drive.google.com/uc?id=1SGtznySLWsbzn9-NPtV5X9ECWhh7W2qD",
		"https://drive.google.com/uc?id=1Sr4gZld2xSPXIDJ1eA_zSuBoQ6ufDVpo",
		"https://drive.google.com/uc?id=1WX-WaNNFq23ZG0NKIzYjDhCVWwP4PN7i",
		"https://drive.google.com/uc?id=1Zh6041kRDRM8OOSPuxAkl638rVnAON7x",
		"https://drive.google.com/uc?id=1a-0HPKO8eLFtCh62CfVI4dW0riwSbmB3",
		"https://drive.google.com/uc?id=1eqikZ7HAvSgPNI6SJAL1QjkESGPbgMGC",
		"https://drive.google.com/uc?id=1i4kheSNblrXtNsUgL-j5m4SNBnEG1o7I",
		"https://drive.google.com/uc?id=1mxQjEsr0n8-2N1BHfBEBw3eder1lTKIp",
		"https://drive.google.com/uc?id=1oajVFSu4B2WRoYCbFeXtX1npgt7MdXqY",
		"https://drive.google.com/uc?id=1oymb5dtlNnA2O4J2QYQ4e6-Zs2mVELQJ",
		"https://drive.google.com/uc?id=1p17N3kED2kXRX-HREqo7Pjd3FYhSkhTG",
		"https://drive.google.com/uc?id=1u5nD0okAJaHaX5al0ZrIq130wkH61KEo",

        "https://drive.google.com/uc?id=1D_iQ7SYanq7a-7z-AyQTl3CI90UESb02", 
		"https://drive.google.com/uc?id=17aKrBMqUCeMl1r23c1-wBIlHddPFYP12", 
		"https://drive.google.com/uc?id=1kKrWsDG1zJgJ5Biwmpf971mfc8Wpi9Wt", 
		"https://drive.google.com/uc?id=1uD-kYg9oTyVMoSNwlauiFXcSbzURD7sj", 
		"https://drive.google.com/uc?id=193SszSiKMka5lPH2SlYSA5hKCOUKq-J7",
		"https://drive.google.com/uc?id=16I0K9s4jWUjH5lIxqNxhA1NEoTzkFPkq",

		"https://drive.google.com/uc?id=12QDDVFKAncbCL5ozDSQG5i74jRADM4q5",
        "https://drive.google.com/uc?id=10GPhw9ty7EqOPohlkYsW1N-ZVvxbOczn",
        "https://drive.google.com/uc?id=1zAcjOH_DjJD7bUzOF_R58X4bm2e9FJKo",
        "https://drive.google.com/uc?id=1Q26Vz0AAgayk4fxt8ftOPhsxdPNi2ZMa",
        "https://drive.google.com/uc?id=1aw_m9E62qJtho5c9FUyF09eJVg4VpyF4",

        "https://drive.google.com/uc?id=1F3ZJ9iiLkycUq7vRUs__CA9_Ekfl-ItI",
        "https://drive.google.com/uc?id=1z6oRYngMo2xfmaFcvFvicFomfSx804hm",
        "https://drive.google.com/uc?id=17PChqKOTmdPJai9rHL2cDYk7GGSAVK2z",
        "https://drive.google.com/uc?id=16PdeQwCzkofWykcWKR6rWaGgUUo5Ew4R",

        "https://drive.google.com/uc?id=1IPctNP-kgasUrPRjFGuk4q9OlvQ6rBPK",
        "https://drive.google.com/uc?id=1IX90Adu1BupYQWm5xkeeoMfcNu-l4DrT",
        "https://drive.google.com/uc?id=1fwf8cNPupxJi7Hig7gKSxEdk6aeVtQLq",
		
        "https://drive.google.com/uc?id=1_UnJCBW1GR4jDc5dDSTJIAdFm_qObnnz",
        "https://drive.google.com/uc?id=1cNyOdzKF55xdSJTp8u6re6a9zzJ_tB9c",
        "https://drive.google.com/uc?id=1gdvaf7PFObdqjAICeG7JsRvE-kmv3hNW"
    ];

    const handleImageClick = (index) => {
        const imageUrl = images[index];
        const fileName = `imagen${index + 1}.jpg`;
        handleDownload(imageUrl, fileName);
    };

    return (
        <div className="carousel-wrapper">
            <Carousel
                autoPlay
                dynamicHeight={true}
                interval={1500}
                infiniteLoop
                thumbWidth={isMobile ? 80 : 120}
                showIndicators={false}
                id="carrousel-img"
                selectedItem={currentIndex}
                onChange={(index) => setCurrentIndex(index)}
            >
                {previewImages.map((previewImageUrl, index) => (
                    <div key={index} onClick={() => handleImageClick(index)}>
                        <img alt="" src={previewImageUrl} />
                        <div className="download-overlay">Descargar</div>
                    </div>
                ))}
            </Carousel>
            <button
                className="download-button"
                onClick={() => handleImageClick(currentIndex)}
            >
                Descargar Imagen
            </button>
        </div>
    );
};
