import Contacto from "../Contacto";
import Acreditaciones from "../Acreditacion";
import mallorca from "../../assets/images/flyershome/Mallorca16Julio.jpeg";
import botonVerde from "../../assets/images/boton-verde.png";
import botonAmarillo from "../../assets/images/boton-amarillo.png";
import botonVioleta from "../../assets/images/boton-violeta.png";
import juntas7y8 from "../../assets/images/flyershome/Barcelona7y8JulioUpdate.jpeg";
import malaga from "../../assets/images/flyershome/malaga.png";
import flyerAlicante from "../../assets/images/flyershome/Flyer-Alicante.jpg";
import flyerValencia from "../../assets/images/flyershome/Flyer-Valencia.jpg";
import flyerMadrid from "../../assets/images/flyershome/Madrid9Julio.jpg";

import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import InstagramFeed from "../InstagramFeed";
import { Col, Container, Row } from "react-bootstrap";

const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "auto"
    });
};

const Inicio = () => {
    const navigate = useNavigate();

    return (
        <>
            <section id="inicio">
                <div id="nav-ciudades">
                    <Link
                        onClick={() => {
                            navigate("/malaga");
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center tickets-festival">
                            <button type="button" id="boton-ciudades">
                                Málaga
                            </button>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            navigate("/barcelona");
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center tickets-festival">
                            <button type="button" id="boton-ciudades">
                                Barcelona
                            </button>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            navigate("/madrid");
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center tickets-festival">
                            <button type="button" id="boton-ciudades">
                                Madrid
                            </button>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            navigate("/mallorca");
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center tickets-festival">
                            <button type="button" id="boton-ciudades">
                                Mallorca
                            </button>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            navigate("/alicante");
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center tickets-festival">
                            <button type="button" id="boton-ciudades">
                                Alicante
                            </button>
                        </div>
                    </Link>
                    <Link
                        onClick={() => {
                            navigate("/valencia");
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center tickets-festival">
                            <button type="button" id="boton-ciudades">
                                Valencia
                            </button>
                        </div>
                    </Link>
                </div>
                {/* FLYERS */}
                <Container className="d-flex justify-content-center">
                    <Row>
                        {/* VALENCIA */}
                        <Col className="text-center flex-column" md={12} lg={4}>
                            <img
                                src={flyerValencia}
                                alt="valencia"
                                width={350}
                                height={500}
                            />
                            <Link
                                onClick={() => {
                                    navigate("/valencia");
                                    goToTop();
                                }}
                            >
                                <img
                                    src={botonVerde}
                                    width={150}
                                    height={30}
                                    alt={botonAmarillo}
                                    id="boton"
                                />
                            </Link>
                        </Col>
                        {/* ALICANTE */}
                        <Col className="text-center flex-column" md={12} lg={4}>
                            <img
                                src={flyerAlicante}
                                alt="alicante"
                                width={350}
                                height={500}
                            />
                            <Link
                                onClick={() => {
                                    navigate("/alicante");
                                    goToTop();
                                }}
                            >
                                <img
                                    src={botonAmarillo}
                                    width={150}
                                    height={30}
                                    alt={botonAmarillo}
                                    id="boton"
                                />
                            </Link>
                        </Col>
                        {/* MALAGA */}
                        <Col className="text-center flex-column" md={12} lg={4}>
                            <img
                                src={malaga}
                                alt="malaga"
                                width={400}
                                
                            />
                            <Link
                                onClick={() => {
                                    navigate("/malaga");
                                    goToTop();
                                }}
                            >
                                <img
                                    src={botonAmarillo}
                                    width={150}
                                    height={30}
                                    alt={botonAmarillo}
                                    id="boton"
                                />
                            </Link>
                        </Col>
                        <Col className="text-center" md={12} lg={4}>
                            <img
                                src={juntas7y8}
                                alt="barcelona"
                                width={400}
                                
                            />
                            <Link
                                onClick={() => {
                                    navigate("/barcelona");
                                    goToTop();
                                }}
                            >
                                <img
                                    src={botonVioleta}
                                    width={150}
                                    height={30}
                                    alt={botonVioleta}
                                    id="boton"
                                />
                            </Link>
                        </Col>
                        <Col className="text-center" md={12} lg={4}>
                            <img
                                src={flyerMadrid}
                                alt="madrid"
                                width={400}
                                
                            />
                            <Link
                                onClick={() => {
                                    navigate("/madrid");
                                    goToTop();
                                }}
                            >
                                <img
                                    src={botonVerde}
                                    width={150}
                                    height={30}
                                    alt={botonVerde}
                                    id="boton"
                                />
                            </Link>
                        </Col>
                        <Col className="text-center" md={12} lg={4}>
                            <img
                                src={mallorca}
                                alt="mallorca"
                                width={400}
                                
                            />
                            <Link
                                onClick={() => {
                                    navigate("/mallorca");
                                    goToTop();
                                }}
                            >
                                <img
                                    src={botonAmarillo}
                                    width={150}
                                    height={30}
                                    alt={botonAmarillo}
                                    id="boton"
                                />
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Contacto />

            <Acreditaciones />

            {/* <InstagramFeed /> */}
        </>
    );
};

export default Inicio;
