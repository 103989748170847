import logo from "../../assets/images/DaleArgentina-logo.svg";
import Instagram from "../../assets/images/Logos/instagram_logo.png";
import { Link, animateScroll as scroll } from "react-scroll";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { MediaQueries } from "../../const/BreakpointsMediaQueries";
import Hamburger from "hamburger-react";
import { useState } from "react";

const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "auto"
    });
};

const volver = () => {
    console.log(window.location.pathname);
    if (window.location.pathname !== "/") {
        window.history.back();
        setTimeout(function () {
            goToTop();
        }, 60);
    }
};

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);
    const navigateTo = () => {
        window.open("https://www.instagram.com/daleargentinafest/");
    };
    const navigate = useNavigate();
    const { isTabletOrMobile } = MediaQueries();
    return (
        <Nav className="navbar navbar-expand-lg fixed-top navbarcustom">
            <button
                className="navbar-toggler text-uppercase font-weight-bold text-white"
                id="menu-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <Hamburger
                    direction="left"
                    color="white"
                    size={25}
                    duration={0.6}
                    toggled={isOpen}
                    toggle={setOpen}
                />
            </button>

            <img
                src={logo}
                alt={logo}
                id="logo-responsive"
                width={100}
                height={55}
                onClick={() => {
                    navigate("/");
                    goToTop();
                }}
            />
            <img
                src={logo}
                alt={logo}
                id="logo"
                width={100}
                height={55}
                onClick={() => {
                    navigate("/");
                    goToTop();
                }}
            />
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav">
                    {/*-----------Inicio-----------*/}
                    <li className="nav-link mx-0 mx-lg-1">
                        {isTabletOrMobile ? (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                to="inicio"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarResponsive"
                                onClick={() => {
                                    navigate("/");
                                    setOpen(false);
                                }}
                            >
                                Inicio
                            </Link>
                        ) : (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                to="inicio"
                                onClick={() => {
                                    navigate("/");
                                    setOpen(false);
                                }}
                            >
                                Inicio
                            </Link>
                        )}
                    </li>
                    {/*-------Sobre el festival--------*/}
                    {/*
                    <li className="nav-link ">
                        {isTabletOrMobile ? (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                to="sobreElFestival"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarResponsive"
                                onClick={() => {
                                    volver();
                                }}
                            >
                                Sobre El Festival
                            </Link>
                        ) : (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                to="sobreElFestival"
                                
                                onClick={() => {
                                    volver();
                                }}
                            >
                                Sobre El Festival
                            </Link>
                        )}
                    </li>
                    */}
                    {/*-----------Contacto-----------*/}
                    <li className="nav-link ">
                        {isTabletOrMobile ? (
                            <>
                                <Link
                                    href="#"
                                    className="nav-link"
                                    activeClass="active"
                                    to="contacto"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/");
                                        setOpen(false);
                                    }}
                                >
                                    Contacto
                                </Link>
                            </>
                        ) : (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                to="contacto"
                                onClick={() => {
                                    navigate("/");
                                    setOpen(false);
                                }}
                            >
                                Contacto
                            </Link>
                        )}
                    </li>
                    {/*-----------Acreditaciones-----------*/}
                    <li className="nav-link mx-0 mx-lg-1">
                        {isTabletOrMobile ? (
                            <>
                                <Link
                                    href="#"
                                    className="nav-link"
                                    activeClass="active"
                                    to="acreditaciones"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/");
                                        setOpen(false);
                                    }}
                                >
                                    Acreditaciones
                                </Link>
                                <Link
                                    href="#"
                                    className="nav-link text-center"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/malaga");
                                        goToTop();
                                        setOpen(false);
                                    }}
                                >
                                    MÁLAGA
                                </Link>
                                <Link
                                    href="#"
                                    className="nav-link text-center"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/barcelona");
                                        goToTop();
                                        setOpen(false);
                                    }}
                                >
                                    BARCELONA
                                </Link>
                                <Link
                                    href="#"
                                    className="nav-link text-center"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/madrid");
                                        goToTop();
                                        setOpen(false);
                                    }}
                                >
                                    MADRID
                                </Link>
                                <Link
                                    href="#"
                                    className="nav-link text-center"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/mallorca");
                                        goToTop();
                                        setOpen(false);
                                    }}
                                >
                                    MALLORCA
                                </Link>
                                <Link
                                    href="#"
                                    className="nav-link text-center"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/alicante");
                                        goToTop();
                                        setOpen(false);
                                    }}
                                >
                                    ALICANTE
                                </Link>
                                <Link
                                    href="#"
                                    className="nav-link text-center"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/valencia");
                                        goToTop();
                                        setOpen(false);
                                    }}
                                >
                                    VALENCIA
                                </Link>
                            </>
                        ) : (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                to="acreditaciones"
                                onClick={() => {
                                    navigate("/");
                                    setOpen(false);
                                }}
                            >
                                Acreditaciones
                            </Link>
                        )}
                    </li>
                    {/*-----------Prensa-----------*/}
                    <li className="nav-link ">
                        {isTabletOrMobile ? (
                            <>
                                <Link
                                    href="#"
                                    className="nav-link"
                                    activeClass="active"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarResponsive"
                                    onClick={() => {
                                        navigate("/prensa");
                                        setOpen(false);
                                        goToTop();
                                    }}
                                >
                                    Prensa
                                </Link>
                            </>
                        ) : (
                            <Link
                                href="#"
                                className="nav-link"
                                activeClass="active"
                                onClick={() => {
                                    navigate("/prensa");
                                    setOpen(false);
                                    goToTop();
                                }}
                            >
                                Prensa
                            </Link>
                        )}
                    </li>
                </ul>
            </div>
            {/*             Chequear Logo INSTA responsive
             */}
            {/*             <img
                src={Instagram}
                alt={Instagram}
                id="Instagram-responsive"
                width={20}
                height={20}
                onClick={() => {
                    navigate("/inicio")
                }}
            /> */}
            {/* -------------------------------------- */}
            <img
                src={Instagram}
                alt={Instagram}
                id="logoInstagram"
                width={20}
                height={20}
                className="-4"
                onClick={() => navigateTo()}
            />
        </Nav>
    );
};

export default Navbar;
